<template>
<loader v-bind="{ loading }">
    <div class="index-rows">
        <div class="box is-marginless" v-for="schedule in schedule" :key="schedule.id">
            <columns>
                <column>
                    <p class="has-text-weight-bold">
                        {{ schedule.checklist.name }}
                    </p>
                </column>
                <column class="is-narrow">
                    <p>
                        Last Performed - <span class="has-text-weight-bold">{{ schedule.last_performed_at | date }}</span>
                    </p>
                </column>
                <column class="is-3 has-text-right">
                    <p>
                        <span v-if="!isOverdue(schedule.due_for)">Due to Be Performed - </span> 
                        <span class="has-text-danger" v-else>Overdue - </span>
                        <span class="has-text-weight-bold"
                            :class="{
                                'has-text-danger': isOverdue(schedule.due_for)
                            }">{{ schedule.due_for | date }}</span>
                    </p>
                </column>
            </columns>
        </div>

        <div v-if="!schedule.length" class="p-3  has-text-centered">
            <p class="is-size-4">There is no checklist schedule available for this equipment.</p>
            <p>If you would like to create a schedule for this piece of equipment </p>
        </div>
    </div>
</loader>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
    
    data: () => ({
        loading: true
    }),

    async beforeCreate() {
       await this.$store.dispatch('equipment/loadSchedule', this.$route.params.equipment)
       this.loading = false
    },

    methods: {
        isOverdue(date) {
            return moment(date).isBefore(moment())
        }
    },

    computed: {
        ...mapGetters('equipment', [
            'schedule'
        ])
    }

}
</script>